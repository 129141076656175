<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div
          v-if="tournamentLoading"
          class="d-flex justify-content-center align-items-center h-100"
        >
          <b-spinner
            label="Loading..."
          />
        </div>

        <div
          v-if="!tournamentLoading && tournament"
          class="email-app-menu"
        >
          <div
            class="text-center compose-btn"
          >
            <tournament-registration-modal
              v-if="tournament"
              :tournament="tournament"
            />

            <tournament-registration-no-team-modal
              v-if="tournament"
              :tournament="tournament"
            />

            <b-button
              v-if="enableJoinButton(tournament)"
              variant="success"
              block
              @click="handleJoinTournamentClick"
            >
              Join now
            </b-button>

            <b-button
              v-if="enableShowAlreadyJoinedButton(tournament)"
              block
              disabled
              variant="info"
            >
              <span class="text-white font-weight-bold">Already joined</span>
            </b-button>

            <b-button
              v-if="enableCheckinButton(tournament)"
              block
              variant="warning"
              :disabled="alreadyCheckedIn(tournament) "
              @click="handleCheckinClick"
            >
              <b-spinner
                v-if="checkingIn"
                label="Checking in..."
                small
              />
              <span
                v-else-if="!alreadyCheckedIn(tournament)"
                class="text-white font-weight-bold"
              >Check-in</span>
              <span
                v-if="alreadyCheckedIn(tournament)"
                class="text-white font-weight-bold"
              >Already checked-in</span>
            </b-button>

            <b-button
              v-if="enableInProgressButton(tournament)"
              block
              disabled
              variant="danger"
            >
              <span class="text-white font-weight-bold">In progress</span>
            </b-button>

            <b-button
              v-if="enableFinishedButton(tournament)"
              block
              disabled
              variant="dark"
            >
              <span class="text-white font-weight-bold">Finished</span>
            </b-button>
          </div>

          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <vertical-nav-menu-items
              :items="menuItems"
              class="navigation navigation-main"
            />

            <!-- Tournament data -->
            <b-row class="m-0">
              <b-col class="d-flex justify-content-between align-items-center m-2">
                <div class="section-label">
                  <strong>{{ tournament?.discipline?.title }}</strong>
                </div>
                <b-avatar
                  :src="disciplineImage"
                  size="35px"
                  square
                />
              </b-col>
            </b-row>

            <!-- Participants joined -->
            <b-row class="m-0 pb-1">
              <b-col>
                <span
                  class="bullet bullet-sm mr-1 bullet-success"
                />
                <span>{{ participantsJoined }}</span>
              </b-col>
            </b-row>

            <b-row
              v-if="tournament?.enable_checkin === true && tournament.checkin_start_at && tournament.checkin_close_at"
              class="m-0 pb-1"
            >
              <b-col>
                <span
                  class="bullet bullet-sm mr-1 bullet-warning"
                />
                <span>Check-in {{ checkinAt }}</span>
              </b-col>
            </b-row>

            <!-- Start at -->
            <b-row class="m-0 pb-1">
              <b-col>
                <span
                  class="bullet bullet-sm mr-1 bullet-primary"
                />
                <span>Start {{ startAt }}</span>
              </b-col>
            </b-row>

            <!-- Tournament format -->
            <b-row class="m-0 pb-1">
              <b-col>
                <span
                  class="bullet bullet-sm mr-1 bullet-success"
                />
                <span>{{ tournamentFormat }}</span>
              </b-col>
            </b-row>

            <b-row
              v-if="tournament?.status_id === TournamentStatusType.PUBLISHED && timeTillStart !== null"
              class="m-0 pb-1"
            >
              <b-col>
                <span
                  class="bullet bullet-sm mr-1 bullet-primary"
                />
                <span>{{ timeTillStart }} left to join</span>
              </b-col>
            </b-row>

            <b-row class="m-0 pb-1">
              <b-col>
                <span
                  class="bullet bullet-sm mr-1 bullet-warning"
                />
                <span>Platforms:</span>
                <b-badge
                  v-for="(platform, index) in tournament?.platforms"
                  :key="index"
                  class="ml-1 mr-1"
                  size="sm"
                  variant="light-primary"
                >
                  {{ platform.title }}
                </b-badge>
              </b-col>
            </b-row>

            <!-- Prizes -->
            <b-row
              v-if="tournament?.prizes"
              class="m-0 pb-1"
            >
              <b-col>
                <div>Prizes:</div>
                <!-- eslint-disable vue/no-v-html -->
                <div v-html="tournament?.prizes" />
                <!--eslint-enable-->
              </b-col>
            </b-row>

            <!-- Entry fee -->
            <b-row
              class="m-0 pb-1"
            >
              <b-col>
                <span>Entry fee: </span>
                <span v-if="tournament?.entry_fee">
                  <b-badge
                    variant="light-warning"
                  >
                    {{ tournament?.entry_fee || 0 }} €
                  </b-badge>
                </span>
                <span v-if="!tournament?.entry_fee">
                  <b-badge
                    variant="light-success"
                  >
                    NO ENTRY FREE
                  </b-badge>
                </span>
              </b-col>
            </b-row>

            <b-row
              class="m-0 mt-2"
            >
              <b-col>
                <div class="d-flex justify-content-between mr-2">
                  <span class="font-weight-bolder">Event host:</span>
                  <span class="ml-1">
                    <feather-icon
                      icon="CheckCircleIcon"
                      style="color: #27AE60"
                    />
                    {{ tournament?.organizer?.name }}
                  </span>
                </div>
                <div class="mb-1 font-small-3 mt-1">
                  Timezone: {{ timezone?.title }}
                </div>
              </b-col>
            </b-row>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BAvatar, BBadge,
  BButton, BCol, BRow, BSpinner, VBModal,
} from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import {
  provide,
} from '@vue/composition-api'
import TournamentParticipantType from '@/constants/TournamentParticipantType'
import { Timezones } from '@/constants/Timezones'
import { timeTillDate, utcToTimezoneFormat } from '@/common/date-utils'
import VerticalNavMenuItems
from '@core/layouts/layout-vertical/components/vertical-nav-menu/components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/useVerticalNavMenu'
import {
  alreadyCheckedIn,
  enableCheckinButton,
  enableFinishedButton,
  enableInProgressButton,
  enableJoinButton, enableShowAlreadyJoinedButton, listMyTournamentParticipants,
} from '@/views/tournament/tournament-utils'
import store from '@/store'
import TournamentStatusType from '@/constants/TournamentStatusType'
import TournamentRegistrationModal from '@/views/tournament/TournamentRegistrationModal.vue'
import { isAfter, isBefore } from 'date-fns'
import router from '@/router'
import TournamentRegistrationNoTeamModal from '@/views/tournament/TournamentRegistrationNoTeamModal.vue'
import time from 'echarts/src/scale/Time'
// eslint-disable-next-line import/no-extraneous-dependencies
import { getDisciplineImage } from '@/views/common'
import TournamentFormat from '@/constants/TournamentFormat'
import { MatchFormatOptions } from '@/constants/MatchFormatOptions'

export default {
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  components: {
    TournamentRegistrationNoTeamModal,
    TournamentRegistrationModal,
    BSpinner,
    VerticalNavMenuItems,
    BAvatar,
    // BSV
    BButton,
    BRow,
    BCol,
    BBadge,
    // 3rd Party
    VuePerfectScrollbar,
  },
  data() {
    return {
      checkingIn: false,

      defaultMenuItems: [
        {
          title: 'Info & rules',
          route: { name: 'tournament-info' },
          icon: 'AlertCircleIcon',
        },
        {
          title: 'Participants',
          icon: 'UsersIcon',
          route: { name: 'tournament-participants' },
        },
        {
          title: 'Standings',
          icon: 'AwardIcon',
          route: { name: 'tournament-standings' },
        },
      ],
      menuItems: this.defaultMenuItems,
    }
  },
  computed: {
    showCheckin() {
      if (!this.tournament?.enable_checkin || !this.tournament?.checkin_close_at || !this.tournament?.checkin_start_at) {
        return false
      }

      return isBefore(new Date(this.tournament.checkin_start_at), new Date()) && isAfter(new Date(this.tournament.checkin_close_at), new Date())
    },
    TournamentStatusType() {
      return TournamentStatusType
    },
    tournament() {
      return this.$store.getters['tournament/getTournament']
    },
    tournamentLoading() {
      return store.getters['tournament/isLoading']
    },
    me() {
      return store.getters['user/getMe']
    },
    // eslint-disable-next-line consistent-return,vue/return-in-computed-property
    tournamentFormat() {
      // ToDo: when multistage is implemented, change how we display tournament format
      // eslint-disable-next-line default-case
      switch (this.tournament?.settings?.first_stage_general_format) {
        case TournamentFormat.RACE:
          return 'Race'
        case TournamentFormat.SINGLE_ELIMINATION:
          return 'Single Elimination'
        case TournamentFormat.DOUBLE_ELIMINATION:
          return 'Double Elimination'
      }
    },
    generalMatchFormat() {
      // ToDo: when multistage is implemented, change how we display match format
      return MatchFormatOptions.find(matchFormat => (matchFormat.code === this.tournament?.settings?.first_stage_general_format))?.label
    },
    disciplineImage() { return getDisciplineImage(this.tournament?.discipline?.id) },

    participantsJoined() {
      return `${
        this.tournament && this.tournament.participants
          ? this.tournament.participants.length
          : 0
      } of ${
        this.tournament && this.tournament.participants_number
          ? this.tournament.participants_number
          : '?'
      } ${
        this.tournament && this.tournament.participant_type_id === TournamentParticipantType.TEAM ? 'teams' : 'players'
      } registered`
    },

    startAt() {
      if (this.tournament) {
        return utcToTimezoneFormat(this.tournament.start_at, this.tournament.timezone_id)
      }

      return null
    },

    checkinAt() {
      if (this.tournament) {
        return utcToTimezoneFormat(this.tournament.checkin_start_at, this.tournament.timezone_id)
      }

      return null
    },

    timezone() {
      return this.tournament
        ? Timezones.find(zone => zone.id === this.tournament.timezone_id)
        : null
    },
    checkin() {
      if (
        !this.tournament
          || !this.tournament.enable_checkin
          || !this.tournament.checkin_start_at
          || !this.tournament.checkin_close_at
      ) {
        return null
      }

      if (new Date(this.tournament?.checkin_start_at) > new Date() && new Date(this.tournament?.checkin_close_at) < new Date()) {
        return null
      }

      return timeTillDate(this.tournament?.checkin_close_at, this.tournament?.timezone_id)
    },

    timeTillStart() {
      let start = this.tournament?.start_at

      if (this.tournament?.enable_registration && this.tournament?.registration_close_at) {
        start = this.tournament?.registration_close_at
      }

      return timeTillDate(start, this.tournament?.timezone_id)
    },

  },
  watch: {
    tournament(newValue) {
      this.generateMenuItems(newValue)
    },
  },
  created() {
    this.generateMenuItems(this.tournament)
  },
  methods: {
    alreadyCheckedIn,
    time,
    utcToTimezoneFormat,
    isBefore,
    generateMenuItems(tournamentLocal) {
      this.menuItems = this.defaultMenuItems

      if (
          tournamentLocal?.settings?.first_stage_structure === TournamentFormat.SINGLE_ELIMINATION
          || tournamentLocal?.settings?.first_stage_structure === TournamentFormat.DOUBLE_ELIMINATION
      ) {
        this.menuItems.splice(-2, 0,
          {
            title: 'Matches',
            icon: 'GridIcon',
            route: { name: 'tournament-matches' },
          })

        this.menuItems.splice(-2, 0,
          {
            title: 'Bracket',
            icon: 'LayersIcon',
            route: { name: 'tournament-bracket' },
          })
      }

      if (tournamentLocal && tournamentLocal.stages.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const stage of tournamentLocal.stages) {
          // eslint-disable-next-line no-unused-expressions
          this.menuItems[1]?.children?.push({
            title: stage.name,
            route: {
              name: 'tournament-stage',
              params: { stageId: stage.id },
            },
          })
        }
      }
    },
    async handleCheckinClick() {
      if (!this.me) {
        // console.log('User not logged in')
        await router.push({ name: 'login' })
      }

      const myParticipants = listMyTournamentParticipants(this.tournament)

      if (!myParticipants || myParticipants.length === 0) {
        return
      }

      this.checkingIn = true

      const { success } = await this.$api.tournament.checkinParticipant(this.tournament.id, myParticipants[0].id)

      this.checkingIn = false

      if (success === false) {
        this.$swal.fire({
          title: 'Error checked-in!',
          text: 'There was a problem checking you in. Please contact our support',
          icon: 'error',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
        })

        return
      }

      this.$swal.fire({
        title: 'Successfully checked-in!',
        text: 'You have successfully checked-in to the tournament! Good luck!',
        icon: 'success',
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Close',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(() => {
        this.$store.dispatch('tournament/fetchTournament', this.tournament.id)
      })

      // eslint-disable-next-line consistent-return
      return true
    },
    async handleJoinTournamentClick() {
      if (!this.me) {
        // console.log('User not logged in')
        await router.push({ name: 'login' })

        return
      }

      // if no team with discipline show modal to create or join team
      if (this.me.teams?.length === 0 || !this.me.teams?.some(team => team.disciplines.some(discipline => discipline.discipline.id === this.tournament.discipline.id))) {
        this.$bvModal.show('tournament-registration-no-team-modal')
      } else {
        this.$bvModal.show('tournament-registration-modal')
      }
    },
  },
  setup(props) {
    const {
      isMouseHovered,
    } = useVerticalNavMenu(props)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      suppressScrollX: true,
    }

    return {
      // UI
      perfectScrollbarSettings,
      isDynamicRouteActive,
      MatchFormatOptions,

      // methods
      enableJoinButton,
      enableShowAlreadyJoinedButton,
      enableFinishedButton,
      enableInProgressButton,
      enableCheckinButton,

      isAfter,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

.sidebar-left {
  ul {
    list-style: none;
    padding: 0;
  }

  .nav-item {
    padding: 0.58rem 1.5rem;
    border: 0;
    font-weight: $font-weight-bold;
    letter-spacing: 0.4px;
    border-left: 2px solid transparent;
    border-radius: 0;

    > a {
      color: #d0d2d6 !important;

      &:hover,
      &:focus,
      &.active, &.router-link-active {
        background: transparent;
        color: #7367f0 !important;
      }
    }
  }

  .navigation-main .nav-item li a svg {
    height: 11px;
    width: 11px;
  }

  .navigation li a i, .navigation li a svg {
    height: 20px;
    width: 20px;
    font-size: 11px;
  }
}
</style>
